<template>
  <div>
    <van-nav-bar
      :title="type === 'add' ? '新增工单' : '工单详情'"
      :border="false"
      fixed
      left-arrow
      @click-left="navBack"
    >
      <template v-if="type !== 'add'" #right>
        <van-icon
          size="24"
          class-prefix="iconfont"
          name="liucheng"
          @click="approveProcess"
        />
      </template>
    </van-nav-bar>
    <div v-if="loaded" class="form-box" :loading="loaded">
      <van-form ref="form" class="form van-form__text-color">
        <template v-if="isShowAuditBtn">
          <van-field
            v-model="form.operationStatus"
            name="operationStatus"
            label="审批"
            placeholder="请选择"
            :required="editable || approveEditable"
            input-align="right"
            error-message-align="right"
            :disabled="!editable && !approveEditable"
            :rules="[
              {
                required: true,
                message: '请选择',
                trigger: 'onChange'
              }
            ]"
          >
            <template #input>
              <van-radio-group
                v-model="form.operationStatus"
                direction="horizontal"
                :disabled="!editable && !approveEditable"
              >
                <van-radio name="1">通过</van-radio>
                <van-radio name="0">退回</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="form.approveContent"
            clickable
            name="approveContent"
            label="意见"
            :placeholder="editable || approveEditable ? '请输入内容' : ''"
            maxlength="500"
            clearable
            :disabled="!editable && !approveEditable"
            input-align="right"
            error-message-align="right"
            :required="
              form.operationStatus === '0' && (editable || approveEditable)
            "
            :rules="[
              {
                required: form.operationStatus === '0',
                message: ' ',
                trigger: 'onChange'
              }
            ]"
          />
        </template>
        <van-field
          v-if="form.id"
          v-model="form.workNo"
          name="workNo"
          label="工单编码"
          placeholder="请输入内容"
          readonly
          :disabled="!editable"
          input-align="right"
          error-message-align="right"
        />
        <van-field
          v-model="form.name"
          clickable
          name="name"
          label="工单名称"
          placeholder="请输入内容"
          :disabled="!editable"
          maxlength="50"
          clearable
          :required="editable"
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          v-model="form.ticketsTypeName"
          clickable
          :is-link="editable"
          name="ticketsTypeName"
          label="工单类型"
          placeholder="请选择"
          :disabled="!editable"
          :required="editable"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="workorderTypeSelect()"
        />
        <van-field
          v-model="form.flowName"
          clickable
          :is-link="editable"
          name="ticketsTypeName"
          label="审批流程"
          :placeholder="editable ? '请选择' : '--'"
          :disabled="!editable"
          :required="editable"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="flowIdSelect()"
        />
        <van-field
          v-model="form.maintenanceRecordApproveName"
          clickable
          :is-link="editable"
          name="ticketsTypeName"
          label="维修审批流程"
          :placeholder="editable ? '请选择' : '--'"
          :disabled="!editable"
          :required="editable"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="maintenanceRecordApproveIdSelect()"
        />
        <van-field
          v-model="form.emergencyType"
          name="emergencyType"
          label="是否应急维修"
          placeholder="请选择"
          :disabled="
            !((isEditField('emergencyType') && isApproveRequired) || editable)
          "
          :required="
            (isEditField('emergencyType') && isApproveRequired) || editable
          "
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
        >
          <template #input>
            <span
              v-if="
                !(
                  (isEditField('emergencyType') && isApproveRequired) ||
                  editable
                )
              "
              class="disabled-color"
              >{{ `${!!Number(form.emergencyType) ? "是" : "否"}` }}</span
            >
            <van-radio-group
              v-else
              v-model="form.emergencyType"
              direction="horizontal"
              :disabled="
                !(
                  (isEditField('emergencyType') && isApproveRequired) ||
                  editable
                )
              "
              @change="emergencyRepairChange"
            >
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="form.planMaintenanceDate"
          name="planMaintenanceDate"
          label="计划维修时间"
          placeholder="请选择"
          :disabled="!editable || form.emergencyType == '1'"
          :required="editable"
          readonly
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="planMaintenanceDateClick"
        />
        <van-popup v-model="showDatePicker" position="bottom">
          <van-datetime-picker
            v-model="datePickerVal"
            type="date"
            @confirm="dateConfirm"
            @cancel="showDatePicker = false"
          />
        </van-popup>
        <van-field
          v-model="form.deviceFaultName"
          clickable
          :is-link="editable"
          name="deviceFaultName"
          label="关联故障"
          :disabled="!editable"
          :placeholder="editable ? '请选择' : '--'"
          clearable
          :required="editable"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          input-align="right"
          error-message-align="right"
          @click="linkFaultSelect()"
        />
        <van-field
          v-model="form.deviceFaultTypeName"
          readonly
          :disabled="!editable"
          name="equipmentInfo"
          label="故障类型"
          :placeholder="editable ? '请输入内容' : '--'"
          input-align="right"
          error-message-align="right"
        >
        </van-field>
        <van-field
          v-model="form.equipmentInfo"
          readonly
          :disabled="!editable"
          name="equipmentInfo"
          label="故障设备"
          :placeholder="editable ? '请输入内容' : '--'"
          input-align="right"
          error-message-align="right"
        >
        </van-field>
        <van-field
          v-model="form.maintenanceDepartmentName"
          clickable
          :is-link="
            (isEditField('maintenanceDepartment') && isApproveRequired) ||
              editable
          "
          :disabled="
            !(
              (isEditField('maintenanceDepartment') && isApproveRequired) ||
              editable
            )
          "
          name="maintenanceDepartmentName"
          label="维修部门"
          placeholder="请选择"
          clearable
          :required="
            (isEditField('maintenanceDepartment') && isApproveRequired) ||
              editable
          "
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          input-align="right"
          error-message-align="right"
          @click="
            ((isEditField('maintenanceDepartment') && isApproveRequired) ||
              editable) &&
              (MDVisiable = true)
          "
        />
        <van-field
          v-model="form.costCenterInfoIdName"
          clickable
          :is-link="editable"
          name="costCenterInfoIdName"
          label="成本中心"
          :placeholder="editable ? '请选择' : '--'"
          :disabled="!editable"
          :required="appName === 'RT'"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: appName === 'RT',
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="costCenterSelect()"
        />
        <van-field
          v-model="form.contractorMaintain"
          name="contractorMaintain"
          label="承包商维修"
          placeholder="请选择"
          :disabled="!editable"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              message: ' ',
              trigger: 'onChange'
            }
          ]"
        >
          <template #input>
            <span v-if="!editable" class="disabled-color">
              {{ `${!!Number(form.contractorMaintain) ? "是" : "否"}` }}
            </span>
            <van-radio-group
              v-else
              v-model="form.contractorMaintain"
              direction="horizontal"
              :disabled="!editable"
            >
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field
          v-if="form.contractorMaintain === '1'"
          v-model="form.contractorsName"
          clickable
          :is-link="editable"
          name="contractors"
          label="承包商单位"
          placeholder="请选择"
          :disabled="!editable"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="contractorsVisiable = true"
        />
        <van-field
          v-model="form.maintenancePlan"
          clickable
          type="textarea"
          name="maintenancePlan"
          label="维修方案"
          placeholder="请输入内容"
          :disabled="
            !((isEditField('maintenancePlan') && isApproveRequired) || editable)
          "
          maxlength="500"
          show-word-limit
          clearable
          :required="
            (isEditField('maintenancePlan') && isApproveRequired) || editable
          "
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          v-model="form.partsNeed"
          clickable
          name="partsNeed"
          label="配件需求"
          :placeholder="editable ? '请输入内容' : '--'"
          :disabled="
            !((isEditField('partsNeed') && isApproveRequired) || editable)
          "
          :required="isEditField('partsNeed') && isApproveRequired"
          maxlength="500"
          type="textarea"
          show-word-limit
          clearable
          input-align="right"
          error-message-align="right"
        />
        <van-field
          v-model="form.toolNeed"
          clickable
          name="toolNeed"
          label="工具需求"
          :placeholder="editable ? '请输入内容' : '--'"
          :disabled="
            !((isEditField('toolNeed') && isApproveRequired) || editable)
          "
          :required="isEditField('toolNeed') && isApproveRequired"
          maxlength="500"
          type="textarea"
          show-word-limit
          clearable
          input-align="right"
          error-message-align="right"
        />
        <van-field
          v-model="form.cost"
          clickable
          name="cost"
          label="费用预估(元)"
          :placeholder="editable ? '请输入内容' : '--'"
          :disabled="!((isEditField('cost') && isApproveRequired) || editable)"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              pattern: /^-?(^\d{1,23})*(\.\d{1,6})?$/,
              message: '仅能输入数字，允许输入23位整数 小数6位',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          v-model="form.costTypeCodeName"
          clickable
          :is-link="
            (isEditField('costTypeCode') && isApproveRequired) || editable
          "
          name="costTypeCodeName"
          label="费用类型"
          :placeholder="editable ? '请选择' : '--'"
          :disabled="
            !((isEditField('costTypeCode') && isApproveRequired) || editable)
          "
          clearable
          input-align="right"
          error-message-align="right"
          :required="isEditField('costTypeCode') && isApproveRequired"
          :rules="[
            {
              message: '请输入费用类型',
              trigger: 'onChange'
            }
          ]"
          @click="workorderCostTypesSelect()"
        />
        <van-field
          v-if="form.costTypeCode === '5'"
          v-model="form.costTypeOtherName"
          clickable
          name="cost"
          label="定义费用类型"
          placeholder="请输入费用类型"
          :disabled="
            !((isEditField('costTypeCode') && isApproveRequired) || editable)
          "
          clearable
          input-align="right"
          :required="isEditField('costTypeCode') && isApproveRequired"
          error-message-align="right"
          :rules="[
            {
              pattern: /^[\u4e00-\u9fa5a-zA-Z0-9\-]{0,20}$/,
              message: '请添加费用类型描述，20字以内',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          class="file"
          name="fileList"
          label="附件"
          readonly
          :disabled="!editable"
        >
          <template #input>
            <FileUploader
              v-model="form.fileList"
              :max-count="5"
              :accept="FILE_ACCEPT_TYPES.IMAGE"
              :unable-upload="!editable"
            />
          </template>
        </van-field>
        <van-field
          v-if="type !== 'add'"
          v-model="form.departmentName"
          readonly
          :disabled="!editable"
          name="equipmentInfo"
          label="申报部门"
          :placeholder="editable ? '请输入内容' : '--'"
          input-align="right"
          error-message-align="right"
        >
        </van-field>
        <van-field
          v-if="type !== 'add'"
          v-model="form.createUserName"
          readonly
          :disabled="!editable"
          name="equipmentInfo"
          label="申报人"
          :placeholder="editable ? '请输入内容' : '--'"
          input-align="right"
          error-message-align="right"
        >
        </van-field>
        <van-field
          v-if="type !== 'add'"
          v-model="form.createTime"
          readonly
          :disabled="!editable"
          name="equipmentInfo"
          label="送审时间"
          :placeholder="editable ? '请输入内容' : '--'"
          input-align="right"
          error-message-align="right"
        >
        </van-field>
      </van-form>
    </div>
    <bottom-fixed-box>
      <template v-if="type === 'add' || form.status === 1">
        <van-button class="btn-default" @click="save">保存</van-button>
        <van-button class="btn-primary" @click="submit">提交</van-button>
      </template>
      <template v-if="isShowAuditBtn">
        <van-button class="btn-primary" @click="approve">提交</van-button>
      </template>
    </bottom-fixed-box>
    <select-popup
      :choose-value="form.ticketsTypeId"
      :data="workorderTypeSltData"
      title="工单类型"
      :visible="workorderTypeVisiable"
      :popup-style="{
        height: '70vh'
      }"
      value-key="value"
      label-key="label"
      @close="workorderTypeVisiable = false"
      @confirm="workorderTypeChange"
    />
    <select-popup
      :choose-value="form.flowIdName"
      :data="flowIdSltData"
      title="审批流程"
      :visible="flowIdVisiable"
      :popup-style="{
        height: '70vh'
      }"
      value-key="id"
      label-key="name"
      @close="flowIdVisiable = false"
      @confirm="flowIdChange"
    />
    <select-popup
      :choose-value="form.maintenanceRecordApproveIdName"
      :data="maintenanceRecordApproveIdSltData"
      title="审批流程"
      :visible="maintenanceRecordApproveIdVisiable"
      :popup-style="{
        height: '70vh'
      }"
      value-key="id"
      label-key="name"
      @close="maintenanceRecordApproveIdVisiable = false"
      @confirm="maintenanceRecordApproveIdChange"
    />
    <select-popup
      :choose-value="form.costTypeCode"
      :data="costTypes"
      title="费用类型"
      :visible="workorderCostTypeVisiable"
      :popup-style="{
        height: '40vh'
      }"
      value-key="code"
      label-key="name"
      @close="workorderCostTypeVisiable = false"
      @confirm="workorderCostTypeChange"
    />
    <SelectPopupGrade
      v-model="form.contractors"
      :multi="true"
      :filterable="false"
      :immediately="false"
      list-key="value"
      title="承包商单位"
      :list="contractorNames"
      :visible="contractorsVisiable"
      @change="contractorsChange"
      @close="contractorsVisiable = false"
    />
    <select-popup
      :choose-value="form.ticketsTypeId"
      :data="costCenterSltData"
      title="成本中心"
      :visible="costCenterVisiable"
      :popup-style="{
        height: '70vh'
      }"
      value-key="id"
      label-key="costCenterName"
      @close="costCenterVisiable = false"
      @confirm="costCenterChange"
    />
    <select-popup
      :choose-value="form.deviceFaultId"
      :data="linkFaultSltData"
      title="关联故障"
      :visible="linkFaultVisiable"
      :popup-style="{
        height: '70vh'
      }"
      value-key="id"
      label-key="description"
      @close="linkFaultVisiable = false"
      @confirm="linkFaultConfirm"
    />
    <department-tree
      v-model="form._maintenanceDepartment"
      :visible="MDVisiable"
      title="维修部门"
      :multiple="true"
      disabled-key="noauth"
      @close="MDVisiable = false"
      @confirm="MDConfirm"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FileUploader from "@/components/fileuploader/FileUploader";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";
import { parseTime } from "@/utils";
import { getPageDeviceFaults, getDeviceFaultInfo } from "@/api/psm/device";
import {
  getWorkorderTypeSltData,
  addWorkOrder,
  updateWorkOrder,
  getWorkorderInfo,
  getTicketsCurrentNode,
  getTicketsPermission,
  approveWorkOrder,
  getListTicketsCostType,
  getTicketsApproveFieldByNodeId,
  getSelectContractorsNames,
  getCostCenterSltData
} from "@/api/psm/workorder";
import { getFlowData } from "./api";

export default {
  components: {
    FileUploader,
    BottomFixedBox
  },
  data() {
    return {
      appName: window?.globalConfig?.VUE_APP_NAME,
      FILE_ACCEPT_TYPES,
      id: this.$route.params.id,
      type: this.$route.params.type,
      editable: this.$route.params.type === "add",
      approveEditable: false,
      isShowAuditBtn: false,
      form: {
        // 审批
        operationStatus: "", // 审批
        approveContent: "", // 意见
        currentNodeId: "",
        // 表单
        department: "",
        workNo: "", // 工单编码
        name: "", // 工单名称
        ticketsTypeId: "", // 工单类型
        ticketsTypeName: "",
        emergencyType: "", // 是否应急维修
        planMaintenanceDate: "", // 计划维修时间
        deviceFaultId: "", // 关联故障
        deviceFaultName: "",
        equipmentId: "", // 故障关联设备id,
        equipmentInfo: "", // 设备位号+名称
        maintenanceDepartment: "", // 维修部门
        _maintenanceDepartment: [],
        maintenanceDepartmentName: "", // 维修部门名称
        maintenancePlan: "", // 维修方案
        fileList: [], // 安全维修方案
        partsNeed: "", // 配件需求
        toolNeed: "", // 工具需求
        cost: "", // 费用预估
        status: "",

        contractorMaintain: "0", // 是否承包商维修
        contractors: "",
        contractorsName: "", // 承包商
        costTypeCode: "", // 费用类型
        costTypeCodeName: "", // 费用类型名称
        costTypeName: "", // 费用类型其他时，必填

        costCenterInfoId: "", //成本中心
        costCenterInfoIdName: "",
        ticketsSourceType: 1 //工单来源
      },
      workorderTypeSltData: [],
      workorderTypeVisiable: false,

      flowIdSltData: [],
      flowIdVisiable: false,

      maintenanceRecordApproveIdSltData: [],
      maintenanceRecordApproveIdVisiable: false,

      linkFaultSltData: [],
      linkFaultVisiable: false,
      MDVisiable: false,
      showDatePicker: false,
      datePickerProp: "planMaintenanceDate",
      datePickerVal: new Date(),

      costTypes: [],
      workorderCostTypeVisiable: false,
      contractorNames: [],
      contractorsVisiable: false,

      fields: [], // 配置的审批字段
      loaded: true,

      costCenterSltData: [],
      costCenterVisiable: false
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    isApproveRequired() {
      return this.form.operationStatus != "0";
    }
  },
  watch: {
    "form.costTypeCode": {
      handler: function(val) {
        if (val != "5") {
          this.form.costTypeName = "";
        }
      },
      deep: true
    },
    "form.contractorMaintain": {
      handler: function(val) {
        if (val != "1") {
          this.form.contractorsName = "";
        }
      },
      deep: true
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  async created() {
    this.costTypes = await getListTicketsCostType();
    this.getBaseSelectContractorsName();
    if (this.id) {
      this.getPageInfo();
    }
    this.$nextTick(() => {
      if (this.type === "add") {
        this.form = {
          ...this.form,
          departmentName: this.userInfo.departmentName, //部门名称
          declareDepartmentCode: this.userInfo.department, //部门编码
          declareCuserName: this.userInfo.loginName, //申报人姓名
          declareCuserId: this.userInfo.id //申报人ID
        };
      }
    });
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    // 添加外部维修
    addExternalRepairs() {},
    isEditField(str) {
      return this.fields.includes(str);
    },
    getContractorsName(contractors) {
      if (!contractors) return "";
      if (Array.isArray(contractors)) {
        return contractors.map(item => item.label).join(",");
      }
      return contractors.label;
    },
    contractorsChange(val) {
      this.form.contractors = val.map(v => v.value).join(",");
      this.form.contractorsName = val.map(v => v.label).join(",");
    },
    async getBaseSelectContractorsName() {
      const params = {
        orgCodeInList: this.userInfo.orgCode,
        state: 0
      };
      this.contractorNames = await getSelectContractorsNames(params);
    },
    // 获取工单审批流节点配置
    async getTicketsApproveFieldByNodeId(id) {
      let res = await getTicketsApproveFieldByNodeId(id);
      res = res || {};
      let arr = Object.entries(res) || [];
      this.fields = arr
        .filter(v => !!v[1])
        .map(v => v[0])
        .map(v => v.replace("Status", ""));
    },
    // 获取详情
    async getPageInfo() {
      this.loaded = false;
      await this.getTicketsApproveFieldByNodeId(this.id);
      getWorkorderInfo(this.id).then(async res => {
        this.editable = res.status === 1;
        res._maintenanceDepartment = res.maintenanceDepartment.split(",");
        res.deviceFaultId = res.deviceFaultId || "";
        res.deviceFaultName = res.deviceFaultName || "";
        let costTypeCodes = this.costTypes.map(v => v.code);
        res.costTypeCode =
          !costTypeCodes.includes(res.costTypeCode) && res.costTypeName
            ? "5"
            : res.costTypeCode;
        res.costTypeCodeName =
          this.costTypes.find(v => v.code === res.costTypeCode)?.name || "";
        res.costTypeName = res.costTypeCode === "5" ? res.costTypeName : "";
        res.contractorsName = (res.contractors?.map(v => v.name) || []).join(
          ","
        );
        res.contractors = res.contractors?.map(v => v.id) || [];
        await this.$nextTick();
        this.form = {
          ...res
        };
        // 获取审批按钮的权限
        this.getTicketsPermission();
        this.loaded = true;
      });
    },
    getTicketsPermission() {
      const params = {
        id: this.id,
        userId: this.userInfo.id
      };
      Promise.all([
        getTicketsPermission(params),
        getTicketsCurrentNode({ id: this.id })
      ]).then(res => {
        const [one, two] = res;
        // 设置是否有审核权限
        this.isShowAuditBtn = one && two && this.form.status == 2;
        this.approveEditable = this.isShowAuditBtn;
        this.form.currentNodeId = two;
      });
    },
    navBack() {
      if (this.editable || this.approveEditable) {
        this.$dialog
          .confirm({
            title: "提醒",
            message: "数据尚未保存，确认返回？"
          })
          .then(() => {
            this.$router.back();
          });
        return;
      }
      this.$router.back();
    },
    async workorderCostTypesSelect() {
      if (
        !(
          (this.isEditField("costTypeCode") && this.isApproveRequired) ||
          this.editable
        )
      )
        return;
      this.workorderCostTypeVisiable = true;
    },
    async workorderTypeSelect() {
      if (!this.editable) return;
      this.workorderTypeSltData = await getWorkorderTypeSltData();
      this.workorderTypeVisiable = true;
    },

    workorderTypeChange(val) {
      this.form.ticketsTypeId = val.value || "";
      this.form.ticketsTypeName = val.label || "";
      this.form.flowId = "";
      this.form.flowName = "";
      this.form.maintenanceRecordApproveId = "";
      this.form.maintenanceRecordApproveName = "";
    },

    workorderCostTypeChange(val) {
      this.form.costTypeCode = val.code || "";
      this.form.costTypeCodeName = val.name || "";
    },

    async flowIdSelect() {
      if (!this.editable) return;
      let { list } = await getFlowData({
        page: 1,
        size: 9999,
        ticketsTypeId: this.form.ticketsTypeId,
        bizType: 1
      });
      this.flowIdSltData = list;
      this.flowIdVisiable = true;
    },
    flowIdChange(val) {
      this.form.flowId = val.id || "";
      this.form.flowName = val.name || "";
    },

    async maintenanceRecordApproveIdSelect() {
      if (!this.editable) return;
      let { list } = await getFlowData({
        page: 1,
        size: 9999,
        ticketsTypeId: this.form.ticketsTypeId,
        bizType: 2
      });
      this.maintenanceRecordApproveIdSltData = list;
      this.maintenanceRecordApproveIdVisiable = true;
    },
    maintenanceRecordApproveIdChange(val) {
      this.form.maintenanceRecordApproveId = val.id || "";
      this.form.maintenanceRecordApproveName = val.name || "";
    },
    // 成本中心获取下拉数据
    async costCenterSelect() {
      if (!this.editable) return;
      let data = await getCostCenterSltData();
      this.costCenterSltData = data.unDelete || data; // 兼容瑞泰、瑞祥版本此接口返回数据结构不一致
      this.costCenterVisiable = true;
    },
    costCenterChange(val) {
      this.form.costCenterInfoId = val.id || "";
      this.form.costCenterInfoIdName = val.costCenterName || "";
    },
    emergencyRepairChange(val) {
      if (val === "1") {
        const curDate = new Date()
          .toLocaleDateString("zh-cn", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          })
          .replace(/\//g, "-");
        this.form.planMaintenanceDate = curDate;
      }
    },
    planMaintenanceDateClick() {
      if (!this.editable || this.form.emergencyType == "1") return;
      this.datePickerProp = "planMaintenanceDate";
      this.showDatePicker = true;
    },
    dateConfirm(time) {
      this.form[this.datePickerProp] = parseTime(time, "{y}-{m}-{d}");
      this.showDatePicker = false;
    },
    async linkFaultSelect() {
      if (!this.editable) return;
      const params = {
        noTicket: true,
        status: 2,
        size: 9999,
        page: 1
      };
      const { list } = await getPageDeviceFaults(params);
      this.linkFaultSltData = list || [];
      this.linkFaultVisiable = true;
    },
    linkFaultConfirm(val) {
      if (val?.id) {
        this.form.deviceFaultId = val.id;
        this.form.deviceFaultName = val.description;
        getDeviceFaultInfo(val.id).then(res => {
          const {
            equipmentRecordNo,
            equipmentRecordName,
            equipmentRecordId,
            department,
            departmentName
          } = res;
          const id = equipmentRecordId[0];
          const no = equipmentRecordNo[0];
          const name = equipmentRecordName[0];
          const _no = no?.length > 10 ? no.slice(0, 10) + "..." : no || "";
          const _name =
            name?.length > 10 ? name.slice(0, 10) + "..." : name || "";
          this.form.equipmentInfo = `${_no}(${_name})`;
          this.form.equipmentId = id;
          this.form.faultReportDepartment = department;
          this.form.faultReportDepartmentName = departmentName;
          this.form.deviceFaultType = res.type;
          this.form.deviceFaultTypeName = res.typeName;
        });
      } else {
        this.form.deviceFaultId = "";
        this.form.deviceFaultName = "";
        this.form.equipmentInfo = "";
        this.form.equipmentId = "";
        this.form.faultReportDepartment = "";
        this.form.faultReportDepartmentName = "";
      }
    },
    MDConfirm(val) {
      this.form._maintenanceDepartment = val.map(i => i.id) || [];
      this.form.maintenanceDepartmentName = val.map(i => i.label).toString();
    },
    formatFormData() {
      this.form.department = this.userInfo.department;
      this.form.maintenanceDepartment = this.form._maintenanceDepartment.toString();
      this.form.contractors = this.form.contractors.toString();
      return this.form;
    },
    save() {
      this.$refs.form.validate().then(async () => {
        this.formatFormData();
        if (this.form.id) {
          await updateWorkOrder(this.form);
        } else {
          await addWorkOrder(1, this.form);
        }
        this.$toast.success("保存成功！");
        this.$router.back();
      });
    },
    submit() {
      this.$refs.form.validate().then(() => {
        this.$dialog
          .confirm({
            title: "提醒",
            message: "提交后不可修改，确认提交？"
          })
          .then(async () => {
            this.formatFormData();
            await addWorkOrder(2, this.form);
            this.$toast.success("提交成功");
            this.$router.back();
          });
      });
    },
    approve() {
      this.$refs.form.validate().then(() => {
        this.$dialog
          .confirm({
            title: "提醒",
            message: "确认提交？"
          })
          .then(async () => {
            this.formatFormData();
            let params = {
              ticketId: this.id, // 工单id
              operationUserId: this.userInfo.id, // 当前操作人id
              department: this.userInfo.department,
              currentNodeId: this.form.currentNodeId, // 当前节点id
              operationStatus: this.form.operationStatus, // 操作意见, 1: 通过, 0: 不通过
              approveContent: this.form.approveContent // 审批信息
            };
            params = { ...this.form, ...params };
            await approveWorkOrder(params);
            this.isShowAuditBtn = false;
            this.approveEditable = false;
            this.form.currentNodeId = "";
            this.getPageInfo();
            this.$toast.success("审批成功");
            // this.$router.back();
          });
      });
    },
    approveProcess() {
      this.$router.push({
        name: "approveProcessRx",
        params: {
          id: this.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.form-box {
  padding: 58px 0 0;
  margin: 0 0 16px;
  height: calc(100vh - 58px);
  overflow: auto;

  .file {
    ::v-deep {
      .van-field__control {
        text-align: right;
      }
    }
  }

  .form {
    padding-bottom: 75px;
  }
}
</style>
